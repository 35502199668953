/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$light-gray: #e6e6e6;
$black: black;
$default-primary-base-color: #16214a;
$default-primary-dark-color: #07182c;
$default-primary-light-color: #183b6a;
$default-secondary-base-color: #ededed;
$default-secondary-dark-color: #f4f2f1;
$default-secondary-light-color: #ffffff;
$font-muli: 'open sans regular';
$font-standard-size: 62.5%;

$default-base-orange-color: #e83508;


$font-open-sans-light: 'open sans light';
$font-open-sans-regular: 'open sans regular';
$font-open-sans-semibold: 'open sans semibold';
$font-open-sans-bold: 'open sans bold';
$font-open-sans-extrabold: 'open sans extra bold';
$font-sofia-pro-black: 'sofia pro black';
