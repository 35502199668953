/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin desktop {
  @media (min-width: 1025px) {
    @content;
  }
}

// should be removed in future releases
// kept as legacy support
@mixin before-desktop {
  @include mobile {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
    @content;
  }
}

// should be removed in future releases
// kept as legacy support
@mixin after-mobile {
  @include desktop {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin standalone {
  /* stylelint-disable-next-line media-feature-name-no-unknown */
  @media all and (display-mode: standalone) {
    @content;
  }
}

@mixin button-secondary {
  padding: 8px;
  background: #e83508;
  border: 1px solid #e83508;
  transition: 0.3s ease-in-out;
  &:hover {
    background: $default-primary-dark-color;
    border: 1px solid $white;
  }
}
@mixin button-primary {
  padding: 8px;
  border: 1px solid $white;
  transition: 0.3s ease-in-out;
  &:hover {
    background: #e83508;
    border: 1px solid #e83508;
  }
}

@mixin slash($grid-area, $height, $margin-right, $margin-top) {
  display: grid;
  grid-area: $grid-area;
  height: $height;
  width: 2px;
  transform: rotate(-20deg);
  margin-left: auto;
  margin-right: $margin-right;
  margin-top: $margin-top;
  background: $default-base-orange-color;
}
